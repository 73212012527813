<template>
  <div>
    <div style="width: 100%;height:30px;">
      <i class="el-icon-back" @click="back()" style="font-size: 30px;color: #409eff;margin-top: 5px"></i>

    </div>
    <div class="model">
      <div style="flex: 0 1 30%">
        <el-card shadow="always"  style="margin: 5px;height: 100px;">
          <span style="font-size: 18px">{{productName}}</span>
        </el-card>

      </div>
      <div style="flex: 0 1 17%" >
        <el-card shadow="always" style="margin: 5px;height: 100px;text-align: center" >
          <div style="color: #409eff;font-weight: bold">在库数量</div>
          <div style="font-size: 34px;margin-top: 10px">
            {{totalNumber}}
          </div>

        </el-card>
      </div>
      <div style="flex: 0 1 17%" >

        <el-card shadow="always" style="margin: 5px;height: 100px;text-align: center" >
          <span style="color: #409eff;font-weight: bold">维修数量</span>
          <div style="font-size: 34px;margin-top: 10px">
            {{fixNumber}}
          </div>
        </el-card>
      </div>
      <div style="flex: 0 1 17%" >
        <el-card shadow="always" style="margin: 5px;height: 100px;text-align: center" >
          <span style="color: #409eff;font-weight: bold">过效期数量</span>
          <div style="font-size: 34px;margin-top: 10px">
            {{expNumber}}
          </div>
        </el-card>
      </div>
      <div style="flex: 0 1 17%" >
        <el-card shadow="always" style="margin: 5px;height: 100px;text-align: center">
          <span style="color: #409eff;font-weight: bold">报废数量</span>
          <div style="font-size: 34px;margin-top: 10px">
            {{badNumber}}
          </div>
        </el-card>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <el-table
          :data="tableData"
          stripe
          style="width: 95%;border: 1px solid #ebeef5;margin: 0 auto;"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
          height="500px"
          v-loading="loading"
      >
        <el-table-column
            prop="product.productCode"
            label="物料编码"
            min-width="120px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            prop="product.productName"
            label="物料名称"
            min-width="180px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            prop="rfidCode"
            label="RFID编码"
            min-width="120px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            prop="assetCode"
            label="资产编码"
            min-width="80px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            prop="product.factoryProductCode"
            label="SKU编码"
            min-width="100px"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            prop="product.factoryProductName"
            label="工厂物料名称"
            min-width="100px"
            :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column
            prop="status"
            label="物料状态"
            min-width="80px"
            :show-overflow-tooltip="true">
          <template v-slot="scope">
            <el-link v-if="scope.row.status===1" type="success" :underline="false">在库</el-link>
            <el-link v-if="scope.row.status===2" type="warning" :underline="false">借出</el-link>
            <el-link v-if="scope.row.status===3" type="success" :underline="false">维修</el-link>
            <el-link v-if="scope.row.status===4" type="warning" :underline="false">报废</el-link>
            <el-link v-if="scope.row.status===5" type="warning" :underline="false">过期</el-link>
          </template>
        </el-table-column>

        <el-table-column
            prop="product.productDescribe"
            label="物料描述"
            min-width="100px"
            :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
            prop="product.price"
            min-width="100px"
            label="单价">
        </el-table-column>
        <el-table-column
            prop="product.weight"
            min-width="100px"
            label="物料重量">
        </el-table-column>
        <el-table-column
            prop="product.unit"
            min-width="100px"
            label="计量单位">
        </el-table-column>

      </el-table>

      <el-pagination @size-change="productHandleSizeChange" @current-change="productHandleCurrentChange"
                     :current-page.sync="materielQuery.pageNo" :page-sizes="[10,20,30,50]"
                     :page-size="materielQuery.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="materielQuery.total"
                     style="margin-left: 3%"
      >
      </el-pagination>
    </div>
  </div>

</template>
<script>

export default {
  name: "ProductDetails",
  data() {
    return {
      loading: true,
      tableData: [],
      number:0,
      personNumber:0,
      deviceNumber:0,
      money:0,
      totalNumber:0,
      fixNumber:0,
      expNumber:0,
      badNumber:0,
      productName:'',
      month:'',
      date:'',
      productListQuery: {
        query: '',
        supplierId: '0',
        productOwnerId: '0',
        productClassId: '0',
        productId: '0',
        bindId: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      materielQuery:{
        query: '',
        supplierId: '0',
        productOwnerId: '0',
        productClassId: '0',
        productId: '0',
        bindId: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
    };
  },
  computed: {

  },
  mounted() {
    this.initProduct();
    this.initMateriel();
  },
  methods: {
    back() {
      this.$router.back();
    },
    initProduct() {
      this.productListQuery.productId=this.$route.params.id;
      this.postRequest("/product/productList/products", this.productListQuery)
          .then(res => {
            if (res) {
            this.productName=res.data[0].productName;
            this.totalNumber=res.data[0].inventory;
            }
          })
    },
    initMateriel() {
      this.materielQuery.productId=this.$route.params.id;
      this.postRequest("/product/stored/list" , this.materielQuery)
          .then(res => {
            if (res) {
              this.loading= false;
              this.tableData = res.data;
              this.materielQuery.total = res.totalItems;
            }
          })
    },
    productHandleSizeChange(val) {
      this.materielQuery.pageSize = val;
      this.initMateriel();
    },
    productHandleCurrentChange(val) {
      this.materielQuery.pageNo = val;
      this.initMateriel();
    },

  }
}
</script>
<style scoped>
.model{
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
::v-deep .header-item {
  font-size: 18px !important;
}
::v-deep .dv-active-ring-chart .active-ring-info .active-ring-name{
  color: #2c3e50 !important;
  font-size: 18px!important;
}
::v-deep .dv-capsule-chart .label-column{
  color: #2c3e50 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 10px;
  font-size: 12px;
  overflow: hidden; /* 隐藏超出容器的内容 */
  text-overflow: ellipsis; /* 用省略号表示被隐藏的内容 */
  white-space: nowrap;
  text-align: center;
}
::v-deep .dv-capsule-chart .capsule-item .capsule-item-column .capsule-item-value{
  color: #2c3e50;
}
.el-table {
  font-size: 12px;
}

.el-link {
  font-size: 12px;
}
</style>
